import React from 'react';
import '../assets/styles/Home.scss';
import TwitterIcon from '../assets/images/twitter.svg';
import LinkedInIcon from '../assets/images/Linkedin icon.svg';
import GitHubIcon from '../assets/images/github.svg';
import H2 from '../elements/H2';

const Home = () => {
  return (
    <main className="main-main">
      <div className="header">
        <H2 className="header-primary">
          Hi, I’m Saheed!
        </H2>
        <p>
          I craft efficient, user-friendly websites and applications for modern, next-generation experiences. My passion lies in transforming ideas into seamless digital experiences that inspire and engage.
        </p>
        <p className="connect">LET’S CONNECT</p>
        <div className="icons-container">
          <a
            href="http://twitter.com/suretrustng"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={TwitterIcon} alt="twitter icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/saheed-oladele"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={LinkedInIcon} alt="linkedin icon" />
          </a>
          <a
            href="http://github.com/suretrust"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={GitHubIcon} alt="github icon" />
          </a>
        </div>
      </div>
    </main>
  );
};

export default Home;
