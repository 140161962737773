import React from 'react';
import '../assets/styles/Contact.scss';
import H2 from '../elements/H2';
import StyledP from '../elements/StyledP';

const Contact = () => {
  return (
    <div style={{minHeight: 'calc(100vh - 200px)'}}>
      <H2 className="header2">Contact me</H2>
      <section id="contact">
        <StyledP>
          If you have an idea for an application, need a feature developed, or have a project requiring skilled coding, I’d be excited to collaborate and bring it to life!
        </StyledP>
        <StyledP>
          You can reach me via <a href="https://www.linkedin.com/in/saheed-oladele/" target="_blank" rel="noopener noreferrer">LinkedIn</a> or <a href="https://twitter.com/suretrustng" target="_blank" rel="noopener noreferrer">X</a>.
        </StyledP>
      </section>
    </div>
  );
};

export default Contact;
