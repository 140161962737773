import styled from 'styled-components';

const StyledP = styled.p`
  background-color: ${props =>
    props.theme.mode === 'dark' ? `#171b27` : 'white'};
  font-size: 16px;

  a {
    color: rgb(145, 156, 255);
    text-decoration: underline;
  }
`;

export default StyledP;
